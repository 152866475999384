@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.header {
  background: var.$color-neutral-0;
  box-shadow: 0 0.5px 3px rgb(16 24 40 / 9%), 0 1px 1px rgb(16 24 40 / 3%);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.logo-container {
  display: flex;
  align-items: center;
  margin: 0 fn.spacing(2);
}

.logo-flex {
  display: flex;
}

.logo-image {
  height: 100%;
  width: 100%;
}

.logo-svg {
  width: 100%; /* Make the SVG take up the full width of the container */
  height: auto; /* Maintain aspect ratio */
  min-width: 118px; /* Prevent the SVG from shrinking below a certain size */
}

.header-content {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.mobile-logo img {
  width: 118px !important;
  height: inherit !important;
  min-width: inherit !important;
  max-width: inherit !important;
}

.header-content-container {
  max-width: var.$maximum-site-content-width;

  &[data-narrow='true'] {
    max-width: var.$maximum-site-content-width-narrow;
  }
}

[data-mobile-nav-open='true'] .header-content {
  transition: height 0s;
  height: 100vh;
}

.mobile-header {
  align-items: center;
  background: var.$color-neutral-0;
  display: flex;
  flex-direction: row;
  height: 72px;
  justify-content: space-between;
  padding-left: var.$default-gutter-width;
  // Reduced right padding accommodates for the mobile nav button touch target extra padding
  padding-right: var.$default-gutter-width / 2;
  width: 100%;
  z-index: 2;
  transition: height 0.1s linear 0.1s;
}

[data-mobile-nav-open='true'] .mobile-header,
[data-mobile-condensed='true'] .mobile-header {
  height: 54px;
}

[data-mobile-nav-open='true'] .mobile-header {
  border-bottom: 1px solid var.$color-border-opaque;
}

.logo {
  height: 29px;
  width: 119px;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  transition: var.$default-animation-timing;
}

.partner-logo {
  width: 120px;
  height: 100%;
}

.mobile-logo {
  flex-grow: 0;
  flex-shrink: 0;
  height: 29px;
  position: absolute;
  transition: var.$default-animation-timing;
  width: 36px;
  overflow: hidden;
}

/**
 * Conditionally hide header elements by sliding them vertically up out of the browser window.
 * - Hide the main logo when the mobile nav is open
 * - Hide the main logo when the header is condensed on mobile
 * - Hide the mobile header cta button when the mobile nav is open
 * - Hide the mobile header cta button when the header is not condensed on mobile
 * - Hide the mobile logo anytime the header is not condensed on mobile or the mobile nav is closed
 */
[data-mobile-nav-open='true'] .logo,
[data-mobile-condensed='true'] .logo,
[data-mobile-condensed='false'][data-mobile-nav-open='false'] .mobile-logo {
  transform: translateY(-90px);
}

[data-condensed='true'] .logo-container,
[data-condensed='false'] .logo-container,
[data-mobile-condensed='false'] .logo-container {
  margin: 0 16px;
}

[data-mobile-condensed='true'] .logo-container {
  margin: 0 0 0 -60px;
}

.mobile-menu-controls {
  align-items: center;
  display: flex;
  height: 34px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.mobile-menu-button,
.mobile-close-button {
  align-items: center;
  background: none;
  border: none;
  color: var.$color-content-primary;
  display: flex;
  padding: 11px fn.spacing(1); // Increases the hit area of the mobile buttons
  position: absolute;
  transition: var.$default-animation-timing;
}

.mobile-close-button span {
  margin-right: fn.spacing(1.5);
  text-decoration: none;
}

[data-mobile-nav-open='true'] {
  .mobile-menu-button {
    transform: translateY(-80px);
  }
}

[data-mobile-nav-open='false'] {
  .mobile-close-button {
    transform: translateX(200px);
  }
}

.mobile-header-actions {
  background: var.$color-neutral-0;
  bottom: fn.spacing(-10);
  position: fixed;
  transition: transform var.$default-animation-timing;
  width: 100%;
  z-index: 3;
}

[data-mobile-nav-open='true'] .mobile-header-actions {
  padding: fn.spacing(2);
  box-shadow: 0 1px 14px rgb(0 0 0 / 12%);
  transform: translateY(-80px);
}

.mobile-header-actions-sign-in-link-container {
  width: auto;
}

.mobile-header-actions-sign-in-link {
  display: inline-block;
}

.mobile-header-actions-cta {
  display: inline-block;
  width: 100%;
  text-align: center;

  &:hover {
    text-decoration: none !important;
  }
}

.sign-in-link {
  display: none;
}

.header-right-section {
  display: flex;
  align-items: center;
  position: absolute;
  right: 95px; // Adjust the cta to allow space for the menu button
  top: 10px; // Vertically center the mobile header cta within the mobile condensed header
  transition: var.$default-animation-timing;
  z-index: 2;
  gap: fn.spacing(2);
  overflow: hidden;
  transform: translateY(0);

  button {
    transition: var.$default-animation-timing;
  }
}

[data-none-phone-number='true'].header-right-section {
  right: 50px;
}

[data-none-phone-number='false'].header-right-section {
  justify-content: flex-end;
}

.call-cta {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: fn.spacing(1);
  background: var.$color-neutral-50;
  border-radius: fn.spacing(3);
  cursor: pointer;
  color: var.$color-blue-500;
  gap: fn.spacing(1);
  overflow: hidden;
  transition: all 300ms;
  transition-delay: 150ms;
  z-index: 2;
}

[data-mobile-condensed='true'] .call-cta,
[data-condensed='true'] .call-cta {
  padding: fn.spacing(0.5);
  width: 34px;
  height: 34px;
}

[data-mobile-nav-open='true'] .header-right-section,
[data-mobile-condensed='false'] .header-right-section {
  transform: translateY(-70px);
  transition: all var.$default-animation-timing;
}

.header-subnav {
  padding: fn.spacing(3);
  background: var.$color-neutral-50;
  display: flex;
  flex-direction: column;
  gap: fn.spacing(2);
  align-items: center;
}

.call-cta-link {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-call-link {
  text-wrap: nowrap;
  width: 125px;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition-delay: 0ms;
  transition-property: opacity;

  span {
    line-height: 100%;
  }
}

.call-cta-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 300ms;
}

[data-open-button-call='false'] .call-cta .call-cta-container {
  padding-left: 12%;
}

[data-call-cta-mobile='true'].call-cta {
  position: absolute;
  right: 40px;
}

[data-mobile-nav-open='true'] [data-call-cta-mobile='true'].call-cta {
  display: none;
}

[data-open-button-call='true'] .button-call-link {
  opacity: 1;
  transition-delay: 310ms;
}

.header-cta {
  display: inline-block;
  text-wrap: nowrap;
  opacity: 0;
  transition-delay: 0ms;
  transition-property: opacity;
  position: absolute;
  width: 0;

  button {
    transition: all var.$default-animation-timing;
    z-index: 1;
  }

  &:hover {
    text-decoration: none;
  }
}

[data-condensed='false'] .header-cta button {
  padding: fn.spacing(1) fn.spacing(2);
}

[data-open-button-call='false'] .header-cta {
  opacity: 1;
  width: auto;
  position: relative;
}

.phone-icon {
  object-fit: none;
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .partner-logo {
    width: 180px;
    height: 100%;
  }

  .header {
    box-shadow: none;

    &[data-condensed='true'] {
      box-shadow: 0 6px 40px -4px rgb(16 24 40 / 11%), 0 9px 12px -3px rgb(16 24 40 / 5%);
    }
  }

  .header-content-container {
    // These paddings normally apply on the medium breakpoint, but due to custom considerations for
    // the header, they don't apply until the design switches to the large designs.
    padding-left: var.$medium-and-up-gutter-width;
    padding-right: var.$medium-and-up-gutter-width;
  }

  .mobile-header {
    background: none;
    padding: 0;
    position: relative;
    width: auto;
  }

  .mobile-logo {
    display: none;
  }

  .header-content {
    align-items: center;
    flex-direction: row;
    height: fn.spacing(12); // 96px
    justify-content: space-between;
    overflow: visible;
    transition: height var.$default-animation-timing;
  }

  // Use the default header height, ignoring if mobile nav flag is set to open
  [data-mobile-nav-open='true'] .header-content {
    height: fn.spacing(12); // 96px
  }

  // Use the condensed header height in any instance where[data-condensed='true']
  [data-condensed='true'] .header-content,
  [data-mobile-nav-open='true'][data-condensed='true'] .header-content {
    height: fn.spacing(8); // 64px
  }

  [data-mobile-nav-open='true'] .mobile-header {
    border: 0;
    height: auto;
    position: relative;
  }

  .logo {
    height: 60px;
    transform: none;
    width: 180px;
  }

  [data-mobile-condensed='true'] .logo,
  [data-mobile-nav-open='true'] .logo {
    transform: none;
  }

  [data-condensed='true'] .logo {
    height: 52px;
    width: 117px;
  }

  .mobile-menu-controls {
    display: none;
  }

  .mobile-menu-button,
  .mobile-close-button {
    display: none;
  }

  .sign-in-link {
    display: inline-block;
    min-width: 60px;
  }

  .header-right-section {
    position: relative;
    top: initial;
    right: initial;
    min-width: 280px;
    max-width: 280px;
    overflow: hidden;
    justify-content: flex-end;
  }

  [data-open-button-call='true'] .call-cta {
    border-radius: fn.spacing(3);
    padding: fn.spacing(1) fn.spacing(2);
  }

  [data-mobile-condensed='false'] .header-right-section {
    display: flex;
    transform: translateY(0);
  }

  [data-condensed='false'] [data-open-button-call='false'] .call-cta {
    padding: fn.spacing(0.5);
    min-width: 42px;
    min-height: 42px;
    width: 42px;
    height: 42px;
  }

  [data-condensed='false'] [data-open-button-call='true'] .call-cta {
    min-height: 42px;
    height: 42px;
  }

  .header-subnav {
    display: none;
  }
}
